<template>
  <div class="flex justify-center align-center">
    <loader :show="loading"></loader>
    <form
      id="login-form"
      class="shadow-md rounded-md flex flex-col"
      @submit="onSubmit"
    >
      <div class="grid grid-cols-1 gap-4 my-4 mx-8">
        <t-alert variant="danger" class="my-2" :show="hasError">
          {{ $t("login_error") }}
        </t-alert>
        <div class="flex flex-col border-b pt-5 pb-3">
          <h1 class="text-left text-xl font-semibold">Rooster</h1>
        </div>
        <div class="flex flex-col">
          <label class="text-left mb-1 font-semibold">E-mail</label>
          <input
            type="email"
            v-model="email"
            placeholder="Email"
            class="rounded-md border p-1 pl-3"
            required
          />
        </div>
        <div class="flex flex-col">
          <label class="text-left mb-1 font-semibold">Password</label>
          <input
            type="password"
            v-model="password"
            placeholder="Password"
            class="rounded-md border p-1 pl-3"
            required
          />
        </div>
        <t-button type="submit" variant="primary" :disabled="loading">
          Login
        </t-button>
        <a :href="forgotPasswordURL" class="text-left underline text-blue-500">
          Forgot password
        </a>
      </div>
    </form>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import { actions, getters } from "@/constants/state";
import { getUserJobFilter } from "@/services/user-service";
import { first, get, toLower } from "lodash";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { Loader },
  data: () => ({
    loading: false,
    hasError: false,
    email: "",
    password: "",
    forgotPasswordURL: process.env.VUE_APP_FORGOT_PASSWORD_URL,
  }),
  methods: {
    ...mapActions({
      login: actions.AUTH_LOGIN_ACTION,
      setMainFilter: actions.SESSION_SET_MAIN_FILTER_ACTION,
      setSchedulerFilter: actions.SESSION_SET_SCHEDULER_FILTER_ACTION,
      setUserJobFilter: actions.SESSION_SET_USER_JOB_FILTER_ACTION,
      setUserJobFilterType: actions.SESSION_SET_USER_JOB_FILTER_TYPE_ACTION,
      setTemplateFilterAction:
        actions.SESSION_SET_TEMPLATE_MANAGER_FILTER_ACTION,
      getJobFilters: actions.JOBS_FETCH_JOB_FILTERS_ACTION,
    }),
    async onSubmit(e) {
      e.preventDefault();
      this.loading = true;
      this.hasError = false;

      try {
        const toAdmin = await this.login({
          email: toLower(this.email),
          password: this.password,
          locationId: this.domainLocationId,
        });

        if (!toAdmin) {
          return;
        }

        await this.loadJobFilters();
        this.$router.push("/main");
      } catch (error) {
        console.error("error", error);
        this.hasError = true;
      }

      this.loading = false;
    },
    async loadJobFilters() {
      let jobFilter = null;
      try {
        const { data } = await getUserJobFilter(this.user.user.id);
        jobFilter = data;
      } catch (error) {
        console.error("DEBUG: get auth user job filter", error);
      }

      try {
        await this.getJobFilters();
        this.setMainFilter({
          week: moment().startOf("week").add(1, "d").format("YYYY-MM-DD"),
          jobFilterId: get(jobFilter, "id", get(first(this.jobFilters), "id")),
        });

        this.setSchedulerFilter({
          horecaLocationId: this.domainLocationId,
          jobFilterId: get(jobFilter, "id", get(first(this.jobFilters), "id")),
        });

        this.setUserJobFilter({
          jobFilterId: get(jobFilter, "id", get(first(this.jobFilters), "id")),
        });

        this.setUserJobFilterType({
          jobFilterTypeId: get(jobFilter, "type_id"),
        });

        this.setTemplateFilterAction({
          horecaLocationId: this.domainLocationId,
        });
      } catch (error) {
        console.error("DEBUG: get location job fiters", error);
      }
    },
  },
  computed: {
    ...mapGetters({
      user: getters.AUTH_USER_GETTER,
      jobFilters: getters.JOBS_JOB_FILTERS_GETTER,
      domainLocationId: getters.DOMAIN_LOCATION_ID_GETTER,
    }),
  },
};
</script>

<style lang="scss" scoped>
#login-form {
  margin-top: 100px;
  width: 420px;
}
</style>
