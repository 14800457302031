import { getters } from "@/constants/state";

export default {
  [getters.SESSION_MAIN_FILTER_GETTER]: (state) => state.mainFilter,
  [getters.SESSION_SCHEDULER_FILTER_GETTER]: (state) => state.schedulerFilter,
  [getters.SESSION_USER_JOB_FILTER_GETTER]: (state) => state.userJobFilter,
  [getters.SESSION_USER_JOB_FILTER_TYPE_GETTER]: (state) =>
    state.userJobFilterType,
  [getters.SESSION_TEMPLATE_MANAGER_FILTER_GETTER]: (state) =>
    state.templateManagerFilter,
  [getters.SESSION_LOCALE_GETTER]: (state) => state.locale,
};
