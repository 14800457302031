export const actions = {
  AUTH_LOGIN_ACTION: "[AUTH] LOGIN ACTION",
  AUTH_REHYDRATE_ACTION: "[AUTH] REHYDRATE ACTION",
  AUTH_LOGOUT_ACTION: "[AUTH] LOGOUT ACTION",

  AGENDAS_FETCH_ACTION: "[AGENDAS] FETCH ACTION",
  AGENDAS_CREATE_ACTION: "[AGENDAS] CREATE ACTION",
  AGENDAS_UPDATE_ACTION: "[AGENDAS] UPDATE ACTION",
  AGENDAS_REMOVE_ACTION: "[AGENDAS] REMOVE ACTION",

  COMMENTS_FETCH_ACTION: "[COMMENTS] FETCH ACTION",
  COMMENTS_CREATE_ACTION: "[COMMENTS] CREATE ACTION",
  COMMENTS_UPDATE_ACTION: "[COMMENTS] UPDATE ACTION",

  HRNOTIFICATIONS_FETCH_ACTION: "[HR NOTIFICATIONS] FETCH ACTION",

  SPECIALJOB_FETCH_LATEST_ACTION: "[SPECIAL JOB] FETCH LATEST ACTION",
  SPECIALJOB_CREATE_ACTION: "[SPECIAL JOB] CREATE ACTION",

  FULLSCHEDULES_FETCH_ACTION: "[FULL SCHEDULES] FETCH ACTION",
  FULLSCHEDULES_FETCH_EXTRA_PERSONNEL_ACTION:
    "[FULL SCHEDULES] FETCH EXTRA PERSONNEL ACTION",
  FULLSCHEDULES_CREATE_ACTION: "[FULL SCHEDULES] CREATE ACTION",
  FULLSCHEDULES_UPDATE_ACTION: "[FULL SCHEDULES] UPDATE ACTION",
  FULLSCHEDULES_PLAN_USER_ACTION: "[FULL SCHEDULES] PLAN USER ACTION",
  FULLSCHEDULES_FETCH_USER_SCHEDULES_ACTION:
    "[FULL SCHEDULES] FETCH USER SCHEDULES ACTION",

  DOMAIN_FETCH_ACTION: "[DOMAIN] FETCH ACTION",

  FREEDAYS_FETCH_OVERVIEW_ACTION: "[FREE DAYS] FETCH OVERVIEW ACTION",
  FREEDAYS_FETCH_USER_FREE_DAYS_ACTION:
    "[FREE DAYS] FETCH USER FREE DAYS ACTION",
  FREEDAYS_UPSERT_USER_FREE_DAYS_ACTION:
    "[FREE DAYS] UPSERT USER FREE DAYS ACTION",

  HOLIDAYS_FETCH_APPROVED_ACTION: "[HOLIDAYS] FETCH APPROVED ACTION",
  HOLIDAYS_FETCH_UNAPPROVED_ACTION: "[HOLIDAYS] FETCH UNAPPROVED ACTION",
  HOLIDAYS_FETCH_CONFIRMED_ACTION: "[HOLIDAYS] FETCH CONFIRMED ACTION",
  HOLIDAYS_FETCH_USER_HOLIDAYS_ACTION: "[HOLIDAYS] FETCH USER HOLIDAYS ACTION",
  HOLIDAYS_CREATE_USER_HOLIDAY_ACTION: "[HOLIDAYS] CREATE USER HOLIDAY ACTION",
  HOLIDAYS_UPDATE_USER_HOLIDAY_ACTION: "[HOLIDAYS] UPDATE USER HOLIDAY ACTION",
  HOLIDAYS_UPDATE_APPROVED_ACTION: "[HOLIDAYS] UPDATE APPROVED ACTION",
  HOLIDAYS_REMOVE_USER_HOLIDAY_ACTION: "[HOLIDAYS] REMOVE USER HOLIDAY ACTION",

  JOBS_FETCH_ACTION: "[JOBS] FETCH ACTION",
  JOBS_FETCH_USER_JOBS_ACTION: "[JOBS] FETCH USER JOBS ACTION",
  JOBS_FETCH_JOB_FILTERS_ACTION: "[JOBS] FETCH JOB FILTERS ACTION",
  JOBS_FETCH_JOB_FILTER_JOBS_ACTION: "[JOBS] FETCH JOB FILTER JOBS ACTION",

  USERS_ENABLED_FETCH_ACTION: "[USERS] FETCH ENABLED USERS ACTION",

  SESSION_SET_MAIN_FILTER_ACTION: "[SESSION] SET MAIN FILTER ACTION",
  SESSION_SET_SCHEDULER_FILTER_ACTION: "[SESSION] SET SCHEDULER FILTER ACTION",
  SESSION_SET_USER_JOB_FILTER_ACTION: "[SESSION] SET USER JOB FILTER ACTION",
  SESSION_SET_USER_JOB_FILTER_TYPE_ACTION:
    "[SESSION] SET USER JOB FILTER TYPE ACTION",
  SESSION_SET_TEMPLATE_MANAGER_FILTER_ACTION:
    "[SESSION] SET TEMPLATE MANAGER FILTER ACTION",
  SESSION_SET_LOCALE_ACTION: "[SESSION] SET LOCALE ACTION",

  TEMPLATES_GET_EDIT_ACTION: "[TEMPLATES] GET EDIT ACTION",
  TEMPLATES_FETCH_ACTION: "[TEMPLATES] FETCH ACTION",
  TEMPLATES_SEARCH_ACTION: "[TEMPLATES] SEARCH ACTION",
  TEMPLATES_FETCH_PAGE_DATA_ACTION: "[TEMPLATES] FETCH PAGE DATA ACTION",
  TEMPLATES_REMOVE_ACTION: "[TEMPLATES] REMOVE ACTION",

  INPUTNOTE_FETCH_ACTION: "[INPUT NOTE] FETCH ACTION",
  INPUTNOTE_UPSERT_ACTION: "[INPUT NOTE] UPSERT ACTION",

  SCHEDULEDT_FETCH_ACTION: "[SCHEDULE DT] FETCH ACTION",
  SCHEDULEDT_PUBLISH_ACTION: "[SCHEDULE DT] PUBLISH ACTION",
  SCHEDULEDT_COPY_FROM_TEMPLATE_ACTION:
    "[SCHEDULE DT] COPY FROM TEMPLATE ACTION",
  SCHEDULEDT_CREATE_ACTION: "[SCHEDULE DT] CREATE ACTION",
  SCHEDULEDT_REMOVE_ACTION: "[SCHEDULE DT] REMOVE ACTION",

  STATS_FETCH_TIMESLOTS_ACTION: "[STATS] FETCH TIMESLOTS ACTION",
  STATS_FETCH_EMPLOYEES_ACTION: "[STATS] FETCH EMPLOYEES ACTION",

  AVAILABLES_FETCH_USER_AVAILABLES_ACTION:
    "[AVAILABLES] FETCH USER AVAILABLES ACTION",
  AVAILABLES_CREATE_USER_AVAILABLE_ACTION:
    "[AVAILABLES] CREATE USER AVAILABLE ACTION",
  AVAILABLES_UPDATE_USER_AVAILABLE_ACTION:
    "[AVAILABLES] UPDATE USER AVAILABLE ACTION",
  AVAILABLES_REMOVE_USER_AVAILABLE_ACTION:
    "[AVAILABLES] REMOVE USER AVAILABLE ACTION",

  NEWS_FETCH_ACTION: "[NEWS] FETCH ACTION",
  NEWS_CREATE_ACTION: "[NEWS] CREATE ACTION",
  NEWS_UPDATE_ACTION: "[NEWS] UPDATE ACTION",
  NEWS_REMOVE_ACTION: "[NEWS] REMOVE ACTION",

  SWAP_SHIFTS_FETCH_PENDING_ACTION: "[SWAP SHIFTS] FETCH PENDING ACTION",
  SWAP_SHIFTS_FETCH_CONFIRMED_ACTION: "[SWAP SHIFTS] FETCH CONFIRMED ACTION",
  SWAP_SHIFTS_UPDATE_ACTION: "[SWAP SHIFTS] UPDATE ACTION",

  SCENARIOS_FETCH_OVERVIEW_ACTION: "[SCENARIOS] FETCH OVERVIEW ACTION",
  SCENARIOS_FETCH_SCENARIO_ACTION: "[SCENARIOS] FETCH SCENARIO ACTION",
  SCENARIOS_CREATE_ACTION: "[SCENARIOS] CREATE SCENARIOS ACTION",
  SCENARIOS_UPDATE_ACTION: "[SCENARIOS] UPDATE SCENARIOS ACTION",
  SCENARIOS_REMOVE_ACTION: "[SCENARIOS] DELETE SCENARIOS ACTION",

  SCENARIO_SHIFTS_FETCH_DATE_SCENARIO_SHIFTS_ACTION:
    "[SCENARIO SHIFTS] FETCH DATE SCENARIO SHIFTS ACTION",

  HORECA_LOCATIONS_FETCH_ACTION: "[HORECA LOCATIONS] FETCH ACTION",

  TEMPLATE_SCHEDULES_FETCH_EVENTS_ACTION:
    "[TEMPLATE SCHEDULES] FETCH EVENTS ACTION",
  TEMPLATE_SCHEDULES_UPSERT_ACTION: "[TEMPLATE SCHEDULES] UPSERT ACTION",
  TEMPLATE_SCHEDULES_UPDATE_ACTION: "[TEMPLATE SCHEDULES] UPDATE ACTION",
  TEMPLATE_SCHEDULES_REMOVE_ACTION: "[TEMPLATE SCHEDULES] REMOVE ACTION",

  TEMPLATE_LOCATIONS_FETCH_TEMPLATES_ACTION:
    "[TEMPLATE LOCATIONS] FETCH TEMPLATES ACTION",

  SET_START_TIMES_ACTION: "[START TIMES] SET START TIMES ACTION",
  ADD_START_TIMES_ACTION: "[START TIMES] ADD START TIMES ACTION",
  DELETE_START_TIMES_ACTION: "[START TIMES] DELETE START TIMES ACTION",

  AVAILABILITY_REMINDER_FETCH_EMPLOYEES_ACTION:
    "[AVAILABILITY REMINDER] FETCH EMPLOYEES ACTION",
};

export const mutations = {
  AUTH_LOGIN_MUTATION: "[AUTH] LOGIN MUTATION",
  AUTH_LOGOUT_MUTATION: "[AUTH] LOGOUT MUTATION",

  AGENDAS_FETCH_MUTATION: "[AGENDAS] FETCH MUTATION",
  AGENDAS_CREATE_MUTATION: "[AGENDAS] CREATE MUTATION",
  AGENDAS_UPDATE_MUTATION: "[AGENDAS] UPDATE MUTATION",
  AGENDAS_REMOVE_MUTATION: "[AGENDAS] REMOVE MUTATION",

  COMMENTS_FETCH_MUTATION: "[COMMENTS] FETCH MUTATION",
  COMMENTS_CREATE_MUTATION: "[COMMENTS] CREATE MUTATION",
  COMMENTS_UPDATE_MUTATION: "[COMMENTS] UPDATE MUTATION",

  HRNOTIFICATIONS_FETCH_MUTATION: "[HR NOTIFICATIONS] FETCH MUTATION",

  SPECIALJOB_FETCH_LATEST_MUTATION: "[SPECIAL JOB] FETCH LATEST MUTATION",
  SPECIALJOB_CREATE_MUTATION: "[SPECIAL JOB] CREATE MUTATION",

  FULLSCHEDULES_FETCH_MUTATION: "[FULL SCHEDULES] FETCH MUTATION",
  FULLSCHEDULES_FETCH_EXTRA_PERSONNEL_MUTATION:
    "[FULL SCHEDULES] FETCH EXTRA PERSONNEL MUTATION",
  FULLSCHEDULES_CREATE_MUTATION: "[FULL SCHEDULES] CREATE MUTATION",
  FULLSCHEDULES_UPDATE_MUTATION: "[FULL SCHEDULES] UPDATE MUTATION",
  FULLSCHEDULES_PLAN_USER_MUTATION: "[FULL SCHEDULES] PLAN USER MUTATION",
  FULLSCHEDULES_FETCH_USER_SCHEDULES_MUTATION:
    "[FULL SCHEDULES] FETCH PERSONAL SCHEDULES MUTATION",

  DOMAIN_FETCH_MUTATION: "[DOMAIN] FETCH MUTATION",

  FREEDAYS_FETCH_OVERVIEW_MUTATION: "[FREE DAYS] FETCH OVERVIEW MUTATION",
  FREEDAYS_FETCH_USER_FREE_DAYS_MUTATION:
    "[FREE DAYS] FETCH USER FREE DAYS MUTATION",
  FREEDAYS_UPSERT_USER_FREE_DAYS_MUTATION:
    "[FREE DAYS] UPSERT USER FREE DAYS MUTATION",

  HOLIDAYS_FETCH_APPROVED_MUTATION: "[HOLIDAYS] FETCH APPROVED MUTATION",
  HOLIDAYS_FETCH_UNAPPROVED_MUTATION: "[HOLIDAYS] FETCH UNAPPROVED MUTATION",
  HOLIDAYS_FETCH_CONFIRMED_MUTATION: "[HOLIDAYS] FETCH CONFIRMED MUTATION",
  HOLIDAYS_FETCH_USER_HOLIDAYS_MUTATION:
    "[HOLIDAYS] FETCH USER HOLIDAYS MUTATION",
  HOLIDAYS_CREATE_USER_HOLIDAY_MUTATION:
    "[HOLIDAYS] CREATE USER HOLIDAY MUTATION",
  HOLIDAYS_UPDATE_USER_HOLIDAY_MUTATION:
    "[HOLIDAYS] UPDATE USER HOLIDAY MUTATION",
  HOLIDAYS_REMOVE_USER_HOLIDAY_MUTATION:
    "[HOLIDAYS] REMOVE USER HOLIDAY MUTATION",
  HOLIDAYS_REMOVE_UNAPPROVED_HOLIDAY_MUTATION:
    "[HOLIDAYS] REMOVE UNAPPROVED HOLIDAY MUTATION",
  HOLIDAYS_REMOVE_CONFIRMED_HOLIDAY_MUTATION:
    "[HOLIDAYS] REMOVE CONFIRMED HOLIDAY MUTATION",

  JOBS_FETCH_MUTATION: "[JOBS] FETCH MUTATION",
  JOBS_FETCH_USER_JOBS_MUTATION: "[JOBS] FETCH USER JOBS MUTATION",
  JOBS_FETCH_JOB_FILTERS_MUTATION: "[JOBS] FETCH JOB FILTERS MUTATION",
  JOBS_FETCH_JOB_FILTER_JOBS_MUTATION: "[JOBS] FETCH JOB FILTER JOBS MUTATION",

  USERS_ENABLED_FETCH_MUTATION: "[USERS] FETCH ENABLED USERS MUTATION",

  SESSION_SET_MAIN_FILTER_MUTATION: "[SESSION] SET MAIN FILTER MUTATION",
  SESSION_SET_SCHEDULER_FILTER_MUTATION:
    "[SESSION] SET SCHEDULER FILTER MUTATION",
  SESSION_SET_USER_JOB_FILTER_MUTATION:
    "[SESSION] SET USER JOB FILTER MUTATION",
  SESSION_SET_USER_JOB_FILTER_TYPE_MUTATION:
    "[SESSION] SET USER JOB FILTER TYPE MUTATION",
  SESSION_SET_TEMPLATE_MANAGER_FILTER_MUTATION:
    "[SESSION] SET TEMPLATE MANAGER FILTER MUTATION",
  SESSION_SET_LOCALE_MUTATION: "[SESSION] SET LOCALE MUTATION",

  TEMPLATES_GET_EDIT_MUTATION: "[TEMPLATES] GET EDIT MUTATION",
  TEMPLATES_FETCH_MUTATION: "[TEMPLATES] FETCH MUTATION",
  TEMPLATES_SEARCH_MUTATION: "[TEMPLATES] SEARCH MUTATION",
  TEMPLATES_FETCH_PAGE_DATA_MUTATION:
    "[TEMPLATES] FETCH TEMPLATE PAGE DATA MUTATION",
  TEMPLATES_REMOVE_MUTATION: "[TEMPLATES] REMOVE MUTATION",

  INPUTNOTE_FETCH_MUTATION: "[INPUT NOTE] FETCH MUTATION",
  INPUTNOTE_UPSERT_MUTATION: "[INPUT NOTE] UPSERT MUTATION",

  SCHEDULEDT_FETCH_MUTATION: "[SCHEDULE DT] FETCH MUTATION",
  SCHEDULEDT_REMOVE_MUTATION: "[SCHEDULE DT] REMOVE MUTATION",

  STATS_FETCH_TIMESLOTS_MUTATION: "[STATS] FETCH TIMESLOTS MUTATION",
  STATS_FETCH_EMPLOYEES_MUTATION: "[STATS] FETCH EMPLOYEES MUTATION",

  AVAILABLES_FETCH_USER_AVAILABLES_MUTATION:
    "[AVAILABLES] FETCH USER AVAILABLES MUTATION",
  AVAILABLES_CREATE_USER_AVAILABLE_MUTATION:
    "[AVAILABLES] CREATE USER AVAILABLE MUTATION",
  AVAILABLES_UPDATE_USER_AVAILABLE_MUTATION:
    "[AVAILABLES] UPDATE USER AVAILABLE MUTATION",
  AVAILABLES_REMOVE_USER_AVAILABLE_MUTATION:
    "[AVAILABLES] REMOVE USER AVAILABLE MUTATION",

  NEWS_FETCH_MUTATION: "[NEWS] FETCH NEWS MUTATION",
  NEWS_CREATE_MUTATION: "[NEWS] CREATE NEWS MUTATION",
  NEWS_UPDATE_MUTATION: "[NEWS] UPDATE NEWS MUTATION",
  NEWS_REMOVE_MUTATION: "[NEWS] REMOVE NEWS MUTATION",

  SWAP_SHIFTS_FETCH_PENDING_MUTATION: "[SWAP SHIFTS] FETCH PENDING MUTATION",
  SWAP_SHIFTS_FETCH_CONFIRMED_MUTATION:
    "[SWAP SHIFTS] FETCH CONFIRMED MUTATION",
  SWAP_SHIFTS_REMOVE_SWAP_SHIFT_MUTATION:
    "[SWAP SHIFTS] REMOVE SWAP SHIFT MUTATION",

  SCENARIOS_FETCH_OVERVIEW_MUTATION: "[SCENARIOS] FETCH OVERVIEW MUTATION",
  SCENARIOS_FETCH_SCENARIO_MUTATION: "[SCENARIOS] FETCH SCENARIO MUTATION",
  SCENARIOS_CREATE_MUTATION: "[SCENARIOS] CREATE SCENARIOS MUTATION",
  SCENARIOS_UPDATE_MUTATION: "[SCENARIOS] UPDATE SCENARIOS MUTATION",
  SCENARIOS_REMOVE_MUTATION: "[SCENARIOS] DELETE SCENARIOS MUTATION",

  SCENARIO_SHIFTS_FETCH_DATE_SCENARIO_SHIFTS_MUTATION:
    "[SCENARIO SHIFTS] FETCH DATE SCENARIO SHIFTS MUTATION",

  HORECA_LOCATIONS_FETCH_MUTATION: "[HORECA LOCATIONS] FETCH MUTATION",

  TEMPLATE_SCHEDULES_FETCH_EVENTS_MUTATION:
    "[TEMPLATE SCHEDULES] FETCH EVENTS MUTATION",
  TEMPLATE_SCHEDULES_UPSERT_MUTATION: "[TEMPLATE SCHEDULES] UPSERT MUTATION",
  TEMPLATE_SCHEDULES_UPDATE_MUTATION: "[TEMPLATE SCHEDULES] UPDATE MUTATION",
  TEMPLATE_SCHEDULES_REMOVE_MUTATION: "[TEMPLATE SCHEDULES] REMOVE MUTATION",

  TEMPLATE_LOCATIONS_FETCH_TEMPLATES_MUTATION:
    "[TEMPLATE LOCATIONS] FETCH TEMPLATES MUTATION",

  SET_START_TIMES_MUTATIONS: "[START TIMES] SET START TIMES MUTATION",
  ADD_START_TIMES_MUTATIONS: "[START TIMES] ADD START TIMES MUTATION",
  DELETE_START_TIMES_MUTATIONS: "[START TIMES] DELETE START TIMES MUTATION",

  AVAILABILITY_REMINDER_FETCH_EMPLOYEES_MUTATION:
    "[AVAILABILITY REMINDER] FETCH EMPLOYEES MUTATION",
};

export const getters = {
  AUTH_TOKEN_GETTER: "[AUTH] TOKEN GETTER",
  AUTH_USER_GETTER: "[AUTH] USER GETTER",
  AUTH_USER_ID_GETTER: "[AUTH] USER ID GETTER",
  AUTH_AUTHENTICATED_GETTER: "[AUTH] AUTHENTICATED GETTER",
  AUTH_IS_ADMIN_OR_SUPERUSER_GETTER: "[AUTH] IS ADMIN GETTER",
  AUTH_IS_FULLTIMER_GETTER: "[AUTH] IS FULLTIMER GETTER",

  AGENDAS_GETTER: "[AGENDAS] AGENDAS GETTER",

  COMMENTS_GETTER: "[COMMENTS] COMMENTS GETTER",

  HRNOTIFICATIONS_GETTER: "[HR NOTIFICATIONS] HRNOTIFICATIONS GETTER",

  SPECIALJOB_LATEST_GETTER: "[SPECIAL JOB] SPECIALJOB LATEST GETTER",

  FULLSCHEDULES_GETTER: "[FULL SCHEDULES] FULLSCHEDULES GETTER",
  FULLSCHEDULES_EXTRA_PERSONNEL_GETTER:
    "[FULL SCHEDULES] EXTRA PERSONNEL GETTER",
  FULLSCHEDULES_USER_SCHEDULES_GETTER: "[FULL SCHEDULES] USER SCHEDULES GETTER",

  DOMAIN_GETTER: "[DOMAIN] DOMAIN GETTER",
  DOMAIN_LOCATION_ID_GETTER: "[DOMAIN] LOCATION ID GETTER",
  DOMAIN_LOCATION_NAME_GETTER: "[DOMAIN] LOCATION NAME GETTER",

  FREEDAYS_OVERVIEW_GETTER: "[FREE DAYS] OVERVIEW GETTER",
  FREEDAYS_USER_FREE_DAYS_GETTER: "[FREE DAYS] USER FREE DAYS GETTER",

  HOLIDAYS_APPROVED_GETTER: "[HOLIDAYS] APPROVED GETTER",
  HOLIDAYS_UNAPPROVED_GETTER: "[HOLIDAYS] UNAPPROVED GETTER",
  HOLIDAYS_CONFIRMED_GETTER: "[HOLIDAYS] CONFIRMED GETTER",
  HOLIDAYS_USER_HOLIDAYS_GETTER: "[HOLIDAYS] USER HOLIDAYS GETTER",

  JOBS_GETTER: "[JOBS] JOBS GETTER",
  JOBS_USER_JOBS_GETTER: "[JOBS] USER JOBS GETTER",
  JOBS_JOB_FILTERS_GETTER: "[JOBS] JOB TYPES GETTER",
  JOBS_JOB_FILTER_JOBS_GETTER: "[JOBS] JOB FILTER JOBS GETTER",

  USERS_ENABLED_GETTER: "[USERS] ENABLED USERS GETTER",

  SESSION_MAIN_FILTER_GETTER: "[SESSION] MAIN FILTER GETTER",
  SESSION_SCHEDULER_FILTER_GETTER: "[SESSION] SCHEDULER FILTER GETTER",
  SESSION_USER_JOB_FILTER_GETTER: "[SESSION] USER JOB FILTER GETTER",
  SESSION_USER_JOB_FILTER_TYPE_GETTER: "[SESSION] USER JOB FILTER TYPE GETTER",
  SESSION_TEMPLATE_MANAGER_FILTER_GETTER:
    "[SESSION] TEMPLATE MANAGER FILTER GETTER",
  SESSION_LOCALE_GETTER: "[SESSION] LOCALE GETTER",

  TEMPLATES_GETTER: "[TEMPLATES] TEMPLATES GETTER",
  TEMPLATES_EDIT_GETTER: "[TEMPLATES] TEMPLATES EDIT GETTER",
  TEMPLATES_SEARCH_GETTER: "[TEMPLATES] TEMPLATES SEARCH GETTER",
  TEMPLATES_PAGE_DATA_GETTER: "[TEMPLATES] TEMPLATES PAGE DATA GETTER",
  TEMPLATES_DAY_SHIFT_COUNT_GETTER:
    "[TEMPLATES] TEMPLATES DAY SHIFT COUNT GETTER",

  INPUTNOTE_GETTER: "[INPUT NOTE] INPUT NOTE GETTER",

  SCHEDULEDT_GETTER: "[SCHEDULE DT] SCHEDULES DT GETTER",

  STATS_TIMESLOTS_GETTER: "[STATS] TIMESLOTS GETTER",
  STATS_EMPLOYEES_GETTER: "[STATS] EMPLOYEES GETTER",

  AVAILABLES_USER_AVAILABLES_GETTER: "[AVAILABLES] USER AVAILABLES GETTER",

  NEWS_GETTER: "[NEWS] NEWS GETTER",

  SWAP_SHIFTS_UNAPPROVED_GETTER: "[SWAP SHIFTS] UNAPPROVED GETTER",
  SWAP_SHIFTS_CONFIRMED_GETTER: "[SWAP SHIFTS] CONFIRMED GETTER",

  SCENARIOS_OVERVIEW_GETTER: "[SCENARIOS] OVERVIEW GETTER",
  SCENARIOS_SCENARIO_GETTER: "[SCENARIOS] SCENARIO GETTER",

  SCENARIO_SHIFTS_DATE_SCENARIO_SHIFTS_GETTER:
    "[SCENARIO SHIFTS] DATE SCENARIO SHIFTS GETTER",

  HORECA_LOCATIONS_GETTER: "[HORECA LOCATIONS] HORECA LOCATIONS GETTER",

  TEMPLATE_SCHEDULES_EVENTS_GETTER:
    "[TEMPLATE SCHEDULES] TEMPLATE SCHEDULES EVENTS GETTER",

  TEMPLATE_LOCATIONS_TEMPLATES_GETTER:
    "[TEMPLATE LOCATIONS] TEMPLATE LOCATIONS TEMPLATES GETTER",

  START_TIMES_GETTER: "[START TIMES] START TIMES GETTER",

  AVAILABILITY_REMINDER_EMPLOYEES_GETTER:
    "[AVAILABILITY REMINDER] EMPLOYEES GETTER",
};
