import { actions, mutations } from "@/constants/state";

export default {
  [actions.SESSION_SET_MAIN_FILTER_ACTION]: async (
    { commit },
    { week, jobFilterId, jobId }
  ) => {
    commit(mutations.SESSION_SET_MAIN_FILTER_MUTATION, {
      week,
      jobFilterId,
      jobId,
    });
  },
  [actions.SESSION_SET_SCHEDULER_FILTER_ACTION]: async (
    { commit },
    { horecaLocationId, jobFilterId }
  ) => {
    commit(mutations.SESSION_SET_SCHEDULER_FILTER_MUTATION, {
      horecaLocationId,
      jobFilterId,
    });
  },
  [actions.SESSION_SET_USER_JOB_FILTER_ACTION]: async (
    { commit },
    { jobFilterId }
  ) => {
    commit(mutations.SESSION_SET_USER_JOB_FILTER_MUTATION, { jobFilterId });
  },
  [actions.SESSION_SET_USER_JOB_FILTER_TYPE_ACTION]: async (
    { commit },
    { jobFilterTypeId }
  ) => {
    commit(
      mutations.SESSION_SET_USER_JOB_FILTER_TYPE_MUTATION,
      jobFilterTypeId
    );
  },
  [actions.SESSION_SET_TEMPLATE_MANAGER_FILTER_ACTION]: async (
    { commit },
    { horecaLocationId }
  ) => {
    commit(mutations.SESSION_SET_TEMPLATE_MANAGER_FILTER_MUTATION, {
      horecaLocationId,
    });
  },
  [actions.SESSION_SET_LOCALE_ACTION]: async ({ commit }, locale) => {
    commit(mutations.SESSION_SET_LOCALE_MUTATION, locale);
  },
};
