import { mutations } from "@/constants/state";

export default {
  [mutations.SESSION_SET_MAIN_FILTER_MUTATION]: (state, payload) => {
    state.mainFilter = payload;
  },

  [mutations.SESSION_SET_SCHEDULER_FILTER_MUTATION]: (state, payload) => {
    state.schedulerFilter = payload;
  },

  [mutations.SESSION_SET_USER_JOB_FILTER_MUTATION]: (state, payload) => {
    state.userJobFilter = payload;
  },

  [mutations.SESSION_SET_USER_JOB_FILTER_TYPE_MUTATION]: (state, payload) => {
    state.userJobFilterType = payload;
  },

  [mutations.SESSION_SET_TEMPLATE_MANAGER_FILTER_MUTATION]: (
    state,
    payload
  ) => {
    state.templateManagerFilter = payload;
  },

  [mutations.SESSION_SET_LOCALE_MUTATION]: (state, payload) => {
    state.locale = payload;
  },
};
